'use client';

import Box from '@mui/material/Box';

export default function Error() {
  return (
    <Box sx={{ display: 'flex' }}>
      <div>Une erreur est survenue</div>
    </Box>
  );
}
